// === NPM
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { Add } from "@mui/icons-material";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { GridRenderCellParams, GridSortModel } from "@mui/x-data-grid-pro";
// === LOCAL
import DeleteAction from "@/components/generics/actions/DeleteAction";
import EditAction from "@/components/generics/actions/EditAction";
import ViewAction from "@/components/generics/actions/ViewAction";
import GenericButton from "@/components/generics/buttons/GenericButton";
import GenericConfirmDialog from "@/components/generics/dialogs/GenericConfirmDialog";
import { FilterConfigurations, GenericFilters } from "@/components/generics/filters/GenericFilters";
import PermissionsCheck from "@/components/generics/PermissionsCheck";
import GenericTable from "@/components/generics/table/GenericTable";
import useTimeout from "@/hooks/useTimeout";
import { ActionsColumnProps, FilterType, HttpStatus, IPagination } from "@/interfaces/global";
import { VaccinationOutletContext } from "@/interfaces/vaccination";
import { CALYPSO_HEADERS, defaultPagination } from "@/resources/AppConstant";
import { Preference, UserSituation } from "@/resources/PermissionConstant";
import { createPayload, toLocaleDateFormat } from "@/resources/utils";
import { routerLinks } from "@/routers/RouterConstant";
import VaccinationService from "@/services/VaccinationService";
import ViewDialog from "./containers/ViewDialog";
import { IScrapping, IScrappingFilters } from "./interface";

export default function ScrappingTracking() {
    const { vaccines, scrappingReasons } = useOutletContext<VaccinationOutletContext>();

    const navigate = useNavigate();

    const [scrappings, setScrappings] = useState<IScrapping[]>([]);
    const [inputFilters, setInputFilters] = useState<IScrappingFilters>({
        id: "",
        date: [null, null],
        dpe: "",
        vaccineGtinCode: [],
        batch: "",
        reason: [],
    });
    const [pagination, setPagination] = useState<IPagination>(defaultPagination);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "date", sort: "desc" }]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [selectedScrappingId, setSelectedScrappingId] = useState<string>(null);
    const [openViewDialog, setOpenViewDialog] = useState<boolean>(false);

    useTimeout(() => setPagination((prev) => ({ ...prev, page: 0 })), [inputFilters]);

    useEffect(() => {
        getScrappings();
    }, [pagination, sortModel]);

    const getScrappings = async () => {
        const payload = {
            page: pagination.page,
            size: pagination.pageSize,
            sorts: sortModel.map((s) => `${s.field},${s.sort}`),
            ...createPayload(inputFilters),
        };
        const res = await VaccinationService.getScrappings(payload);
        if (res.status === HttpStatus.OK) {
            setScrappings(res.data);
            setRowCount(+res.headers[CALYPSO_HEADERS.TABLE_COUNT]);
        }
    };

    const handleDelete = async (confirm: boolean) => {
        if (!confirm) {
            setOpenDeleteDialog(false);
            setSelectedScrappingId(null);
            return;
        }
        const res = await VaccinationService.deleteScrapping(selectedScrappingId);
        if (res.status === HttpStatus.NO_CONTENT) {
            setOpenDeleteDialog(false);
            setSelectedScrappingId(null);
            toast.success("Mise au rebut supprimée avec succès");
            getScrappings();
        }
    };

    const handlePageSizeChange = (pageSize: number) => {
        setPagination({ pageSize, page: 0 });
    };

    const handlePageChange = (page: number) => {
        setPagination({ ...pagination, page });
    };

    const columns = [
        {
            field: "id",
            headerName: "N° mise au rebut",
            flex: 1,
        },
        {
            field: "date",
            headerName: "Date de mise au rebut",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) => toLocaleDateFormat(params.row.date),
        },
        {
            field: "dpeId",
            headerName: "N° ordinal DPE",
            flex: 1,
        },
        {
            field: "dpeName",
            headerName: "Nom du DPE",
            flex: 1,
        },
        {
            field: "vaccineGtinCode",
            headerName: "Vaccin",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) =>
                `${params.row.vaccineName} ${vaccines.find((vaccine) => vaccine.gtinCode === params.row.vaccineGtinCode)?.packaging}`,
        },
        {
            field: "batch",
            headerName: "N° de lot du vaccin",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) => params.row.batch.number,
        },
        {
            field: "reason",
            headerName: "Motif",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) =>
                scrappingReasons.find((reason) => reason.key === params.row.reason)?.label,
        },
        {
            field: "quantity",
            headerName: "Quantité",
            flex: 1,
        },
        {
            ...ActionsColumnProps,

            width: 150,
            renderCell: (params: GridRenderCellParams) => (
                <Box>
                    <ViewAction
                        title="Visualiser"
                        onClick={() => {
                            setOpenViewDialog(true);
                            setSelectedScrappingId(params.row.id);
                        }}
                    />
                    <PermissionsCheck
                        requiredPermissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                        preferences={[Preference.IAHP, Preference.CATTLE, Preference.SHEEP]}
                    >
                        <EditAction
                            title="Modifier"
                            onClick={() => {
                                const { id, batch, ...rest } = params.row;
                                delete rest.dpeName;
                                navigate(routerLinks.vaccinationCampaign.vaccine.scrappingDeclare(), {
                                    state: {
                                        scrapping: { ...rest, batchUuid: batch.uuid },
                                        id: id,
                                        batch: batch,
                                    },
                                });
                            }}
                        />
                        <DeleteAction
                            title="Supprimer"
                            onClick={() => {
                                setSelectedScrappingId(params.row.id);
                                setOpenDeleteDialog(true);
                            }}
                        />
                    </PermissionsCheck>
                </Box>
            ),
        },
    ];

    const filterConfigurations: FilterConfigurations<IScrappingFilters> = {
        id: { label: "N° mise au rebut", type: FilterType.NUMBER },
        date: { label: "Date de mise au rebut", type: FilterType.DATEPICKER },
        dpe: { label: "DPE", type: FilterType.INPUT },
        vaccineGtinCode: {
            label: "Vaccin",
            type: FilterType.SELECT_AUTOCOMPLETE,
            values: vaccines.map((vaccine) => ({
                key: vaccine.gtinCode,
                label: `${vaccine.name} ${vaccine.packaging}`,
            })),
        },
        batch: {
            label: "N° de lot du vaccin",
            type: FilterType.INPUT,
        },
        reason: {
            label: "Motif",
            type: FilterType.SELECT_AUTOCOMPLETE,
            values: scrappingReasons,
        },
    };

    return (
        <>
            <Stack width="100%" spacing={2}>
                <Typography variant="h4">Mes mises au rebut</Typography>
                <PermissionsCheck
                    requiredPermissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                    preferences={[Preference.IAHP, Preference.CATTLE, Preference.SHEEP]}
                >
                    <Box display="flex" justifyContent="flex-end">
                        <GenericButton
                            label="Nouvelle mise au rebut"
                            startIcon={<Add />}
                            onClick={() => navigate(routerLinks.vaccinationCampaign.vaccine.scrappingDeclare())}
                        />
                    </Box>
                </PermissionsCheck>
                <Card>
                    <CardContent>
                        <GenericFilters
                            inputFilters={inputFilters}
                            filterConfigurations={filterConfigurations}
                            initialValues={{
                                id: "",
                                date: [null, null],
                                dpe: "",
                                vaccineGtinCode: [],
                                batch: "",
                                reason: [],
                            }}
                            setInputFilters={setInputFilters}
                        />

                        <GenericTable
                            rows={scrappings}
                            columns={columns}
                            getRowId={(row) => row.id}
                            onPageSizeChange={handlePageSizeChange}
                            onPageChange={handlePageChange}
                            page={pagination.page}
                            pageSize={pagination.pageSize}
                            autoHeight
                            sortingMode="server"
                            paginationMode="server"
                            sortModel={sortModel}
                            rowCount={rowCount}
                            onSortModelChange={(model) => setSortModel(model)}
                            sortingOrder={["asc", "desc"]}
                            filterMode="server"
                        />
                    </CardContent>
                </Card>
            </Stack>

            {openDeleteDialog && (
                <GenericConfirmDialog
                    onClose={handleDelete}
                    title={`Suppression de la mise au rebut n° ${selectedScrappingId}`}
                    message="Toute suppression est définitive, êtes-vous certain de vouloir supprimer cette mise au rebut ?"
                />
            )}
            {openViewDialog && (
                <ViewDialog
                    onClose={() => setOpenViewDialog(false)}
                    scrapping={scrappings.find((s) => s.id === selectedScrappingId)}
                />
            )}
        </>
    );
}
