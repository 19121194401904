// === NPM
import React, { useContext, useState } from "react";
import { CancelRounded } from "@mui/icons-material";
import {
    Badge,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import { DateTime } from "luxon";
// === LOCAL
import { ReactComponent as Amv } from "@/assets/icons/billing/amv.svg";
import { ReactComponent as Lock } from "@/assets/icons/billing/lock.svg";
import { ReactComponent as Unlock } from "@/assets/icons/billing/unlock.svg";
import { ReactComponent as Vat } from "@/assets/icons/billing/vat.svg";
import { ReactComponent as Calendar } from "@/assets/icons/shared/calendar.svg";
import { ReactComponent as Paper } from "@/assets/icons/shared/paper.svg";
import DeleteAction from "@/components/generics/actions/DeleteAction";
import EditAction from "@/components/generics/actions/EditAction";
import { GenericIconText } from "@/components/generics/text/GenericIconText";
import SecondaryButton from "@/components/styled/SecondaryButton";
import {
    IChargeableCreateUpdate,
    IChargeableRequiredFields,
    IChargeableVersion,
} from "@/components/VaccinationIahp/Billing/interface";
import { IReferential } from "@/interfaces/referential";
import { colors } from "@/resources/CssConstant";
import { isDateInRange, toLocaleDateFormat } from "@/resources/utils";
import { AmvReferentialsContext, VatReferentialsContext } from "../../../../Management";
import ChargeableVersionForm from "./ChargeableVersionForm";

interface ChargeableVersionTileProps {
    version: IChargeableVersion;
    previousValues?: IChargeableVersion;
    nextValues?: IChargeableVersion;
    requiredFields: IChargeableRequiredFields;
    updateVersion: (data: IChargeableCreateUpdate, uuid: string) => void;
    deleteVersion: (uuid: string) => void;
}
export default function ChargeableVersionTile({
    version,
    previousValues,
    nextValues,
    requiredFields,
    updateVersion,
    deleteVersion,
}: ChargeableVersionTileProps) {
    const vatReferentials: IReferential[] = useContext(VatReferentialsContext);
    const amvReferentials: IReferential[] = useContext(AmvReferentialsContext);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

    const renderActiveElements = () => (
        <>
            <GenericIconText
                icon={<Vat />}
                iconTooltip="Taux de TVA"
                text={vatReferentials.find((vat) => vat.key === version.vatKey)?.label}
            />
            {requiredFields.amv && (
                <GenericIconText
                    icon={<Amv />}
                    iconTooltip="Référence AMV"
                    text={`${amvReferentials.find((amv) => amv.key === version.amvKey)?.label} : x${version.amvMultiplierFactor.toFixed(4)}`}
                />
            )}
            {requiredFields.externalMultiplier && (
                <GenericIconText
                    icon={<Paper />}
                    iconTooltip={version.externalMultiplierDescription}
                    text={`Multiplicateur externe : x${version.externalMultiplierFactor.toFixed(4)}`}
                />
            )}
        </>
    );

    const renderView = () => (
        <Badge
            color="primary"
            badgeContent={
                <Tooltip title="Cette version est actuellement en vigueur">
                    <span>!</span>
                </Tooltip>
            }
            sx={{
                "width": "100%",
                "& .MuiBadge-badge": {
                    right: 16,
                    top: 16,
                },
            }}
            invisible={
                !isDateInRange(DateTime.now().toLocaleString(), [version.startDate ?? "2023-01-01", version.endDate])
            }
        >
            <Card variant="outlined" sx={{ paddingY: 1, paddingX: 2, width: "100%" }}>
                <CardHeader
                    titleTypographyProps={{ fontSize: 18, fontWeight: 500, color: colors.primaryColor }}
                    title={`Version ${version.version}`}
                    action={
                        !version.used && (
                            <>
                                <EditAction title="Modifier" onClick={() => setIsEditing(true)} />
                                {!version.endDate && version.version > 1 && (
                                    <DeleteAction title="Supprimer" onClick={() => setConfirmDelete(true)} />
                                )}
                            </>
                        )
                    }
                />
                <CardContent>
                    <Stack spacing={2}>
                        <Stack spacing={2} direction="row">
                            <Chip
                                icon={
                                    version.used ? (
                                        <Lock style={{ color: colors.primaryColor }} />
                                    ) : (
                                        <Unlock style={{ color: colors.primaryColor }} />
                                    )
                                }
                                label={version.used ? "Utilisée" : "Inutilisée"}
                                sx={{
                                    p: 1,
                                    border: `3px solid ${colors.primaryColor}`,
                                    color: colors.primaryColor,
                                    fontSize: 16,
                                    fontWeight: 600,
                                    backgroundColor: colors.white,
                                    width: "fit-content",
                                }}
                            />
                            {!version.active && (
                                <Chip
                                    icon={<CancelRounded />}
                                    label="Inactive"
                                    sx={{
                                        border: `3px solid #616161`,
                                        fontSize: 16,
                                        fontWeight: 500,
                                    }}
                                />
                            )}
                        </Stack>
                        <GenericIconText
                            icon={<Calendar />}
                            iconTooltip="Dates de validité"
                            text={
                                version.endDate
                                    ? `Du ${toLocaleDateFormat(version.startDate) ?? "01/01/2023"} au ${toLocaleDateFormat(version.endDate)}`
                                    : `Débute le ${toLocaleDateFormat(version.startDate) ?? "01/01/2023"}`
                            }
                        />
                        {version.active && renderActiveElements()}
                        {confirmDelete && (
                            <Typography variant="h5" color="error">
                                Êtes-vous sûr de vouloir supprimer cette version ?
                            </Typography>
                        )}
                    </Stack>
                </CardContent>
                {confirmDelete && (
                    <CardActions sx={{ justifyContent: "center" }}>
                        <SecondaryButton onClick={() => setConfirmDelete(false)} variant="outlined">
                            Non
                        </SecondaryButton>
                        <Button
                            onClick={() => {
                                deleteVersion(version.uuid);
                                setConfirmDelete(false);
                            }}
                            variant="contained"
                            color="primary"
                        >
                            Oui
                        </Button>
                    </CardActions>
                )}
            </Card>
        </Badge>
    );

    return isEditing ? (
        <ChargeableVersionForm
            version={version}
            previousValues={previousValues}
            nextValues={nextValues}
            onCancel={() => setIsEditing(false)}
            onSubmit={updateVersion}
            requiredFields={requiredFields}
        />
    ) : (
        renderView()
    );
}
